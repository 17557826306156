import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Login.css";

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });
  const [resetFields, handleResetFieldChange] = useFormFields({
    resetPassword: "",
    resetPassword2: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validateResetForm() {
    return resetFields.resetPassword.length > 0 && resetFields.resetPassword === resetFields.resetPassword2;
  }

  async function handleLoginSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      var cognitoUser = await Auth.signIn(fields.email, fields.password);
      if (cognitoUser.challengeName) {
        setCognitoUser(cognitoUser);
        setResetPassword(true);
      } else {
        props.userHasAuthenticated(true);
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleResetSubmit(event) {
    event.preventDefault();
    setIsResetLoading(true);
    try {
      await Auth.completeNewPassword(cognitoUser, resetFields.resetPassword);
      props.userHasAuthenticated(true);
    } catch (e) {
      onError(e);
      setIsResetLoading(false);
    }
  }
  function renderLoginForm() {
    return (
      <div className="LoginForm">
        <form onSubmit={handleLoginSubmit}>
          <FormGroup controlId="email" bssize="large">
            <FormLabel>Email</FormLabel>
            <FormControl
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bssize="large">
            <FormLabel>Password</FormLabel>
            <FormControl
              value={fields.password}
              onChange={handleFieldChange}
              type="password"
            />
          </FormGroup>
          <Link to="/login/reset">Forgot password?</Link>
          <LoaderButton
            block
            type="submit"
            bssize="large"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Login
          </LoaderButton>
        </form>
      </div>
    );
  }

  function renderResetForm() {
    return (
      <div className="ResetForm">
        <form onSubmit={handleResetSubmit}>
          You are required to change your password before continuing
          <FormGroup controlId="resetPassword" bssize="large">
            <FormLabel>Password</FormLabel>
            <FormControl
              autoFocus
              type="password"
              value={resetFields.resetPassword}
              onChange={handleResetFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="resetPassword2" bssize="large">
            <FormLabel>Confirm Password</FormLabel>
            <FormControl
              value={resetFields.resetPassword2}
              onChange={handleResetFieldChange}
              type="password"
            />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bssize="large"
            isLoading={isResetLoading}
            disabled={!validateResetForm()}
          >
            Set new password
          </LoaderButton>
        </form>
      </div>
    );
  }

  return (
    <div className="Login">
      {!resetPassword
        ? renderLoginForm()
        : renderResetForm()}
    </div>
  );

}