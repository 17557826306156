import React, { PureComponent } from 'react'
import { Card } from "react-bootstrap";


export default class BbqTempReading extends PureComponent {
  constructor(props) {
    super(props);
    this.probeNum = props.probe;
    this.state = {temperature: '...'}
    props.onSocketConnection((s) => {
      s.addEventListener('message', this.onWsMessage);
    })
  }

  onWsMessage = (e) => {
    var msg = JSON.parse(e.data);
    var j;
    var vals = null;
    if (msg.type === 'data') {
      if (msg.data.length > 0) {
        vals = msg.data[msg.data.length-1].vals;
      }
    }
    if (msg.type === 'temperatures') {
      vals = msg.vals;
    }
    if (vals !== null) {
      for (j = 0; j < vals.length; j++) {
        if (j+1 === this.probeNum) {
          this.setState(Object.assign({}, this.state, {temperature: vals[j]}));
        }
      }
    }
  }

  render() {
    return (
      <Card>
        <Card.Title>Probe {this.probeNum}</Card.Title>
        <Card.Body>
          <p align="right" size="large">{this.state.temperature}&deg;F</p>
        </Card.Body>
      </Card>
    );
  }
}