import React from "react";
import ConnectedProbesTable from "../components/ProbeConnectedTable";


export default function Probes(props) {

  return (
      <div className="ConnectedProbes">
        <h3>Connected Probes</h3>
        <ConnectedProbesTable />
      </div>
  );
}