import React, { useState, useEffect } from "react";
import "./Home.css";
import Login from "./Login";
import MainPage from "./MainPage";

// Main function
export default function Home(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }
      setIsLoading(false);
    }
    onLoad();
  }, [props.isAuthenticated]);

  // Draw the page
  function renderAuthedPage() {
    return (
      <MainPage {...props} />
    )
  }

  // What we display to an authenticated user
  function renderLoadingPage() {
    return (
      <>
        {!isLoading && renderAuthedPage()}
      </>
    )
  }

  // What we display to an unauthenticated user
  function renderLander() {
    return (
      <Login userHasAuthenticated={props.userHasAuthenticated} />
    );
  }

  // Determine if authenticated - display correct page
  return (
    <div className="Home">
      {props.isAuthenticated ? renderLoadingPage() : renderLander()}
    </div>
  );
}

