import React, { PureComponent } from "react";
import { DropdownButton, Dropdown, Table, th, tr, td, thead, tbody } from "react-bootstrap";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";


export default class ConnectedProbesTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {probe1: "...",
                  probe2: "...",
                  probe3: "...",
                  probe4: "...",
                  probe5: "...",
                  probe6: "...",
                  available: []
    };
  }

  componentDidMount() {
    this.updateTable();
  }


  async updateTable() {
    var retValue = await API.get("bbq-api", "/connectedProbes");
    console.log(retValue);
    this.setState({probe1: retValue.connected.probe1 || "",
                   probe2: retValue.connected.probe2 || "",
                   probe3: retValue.connected.probe3 || "",
                   probe4: retValue.connected.probe4 || "",
                   probe5: retValue.connected.probe5 || "",
                   probe6: retValue.connected.probe6 || "",
                   available: retValue.available});
  }


  async handleSave() {
    try {
      await API.put("bbq-api", "/connectedProbes", {
        body: {
          probe1: this.state.probe1,
          probe2: this.state.probe2,
          probe3: this.state.probe3,
          probe4: this.state.probe4,
          probe5: this.state.probe5,
          probe6: this.state.probe6
        }
      });
    } catch (e) {
      console.log(e);
    }
    this.updateTable();
  }


  setFunc = (probeNum, probeName) => {
    console.log(probeNum);
    console.log(probeName);
    var newProbeSetting = {};
    newProbeSetting["probe" + probeNum] = probeName;
    this.setState(
      Object.assign({}, this.state, newProbeSetting)
    );
  }


  getDropdownOptions(probeNum) {
    var r = [];
    this.state.available.forEach(function(probeType) { r.push(<Dropdown.Item onClick={function() {this.setFunc(probeNum, probeType.name)}.bind(this) }>{probeType.name}</Dropdown.Item>)}.bind(this));
    return r;
  }


  render() {
    return (
      <>
        <Table striped >
          <thead>
            <tr>
              <th>Probe Number</th>
              <th>Probe Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <DropdownButton title={this.state.probe1}>{this.getDropdownOptions(1)}</DropdownButton>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <DropdownButton title={this.state.probe2}>{this.getDropdownOptions(2)}</DropdownButton>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <DropdownButton title={this.state.probe3}>{this.getDropdownOptions(3)}</DropdownButton>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <DropdownButton title={this.state.probe4}>{this.getDropdownOptions(4)}</DropdownButton>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <DropdownButton title={this.state.probe5}>{this.getDropdownOptions(5)}</DropdownButton>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                <DropdownButton title={this.state.probe6}>{this.getDropdownOptions(6)}</DropdownButton>
              </td>
            </tr>
          </tbody>
        </Table>
        <LoaderButton type="button" className="btn btn-primary" onClick={this.handleSave.bind(this)}>
          Save
        </LoaderButton>
      </>
    );
  }
}