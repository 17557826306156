import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { Card } from 'react-bootstrap';

export default function Settings(props) {
  // State settings for modal
  const [userDetails, setUserDetails] = useState({"email":"","serial":"","firmwareVersion":"","lastTime":0});
  const [postUrl, setPostUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    updatePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updatePage() {
    var retValue = await API.get("garage-api", "/settings");
    setPostUrl(retValue.url || '');
    setUserDetails({"email":retValue.email,"serial":retValue.serial,"firmwareVersion":retValue.firmwareVersion,"lastTime":retValue.timestamp})
  }

  function changePassword() {
    props.history.push("/settings/password");
  }

  async function globalSignout() {
    await Auth.signOut({ global: true });
    props.userHasAuthenticated(false);
    props.history.push("/login");
  }

  async function saveSettings() {
    setIsLoading(true);
    try {
      await API.post("garage-api", "/settings", {
        body: {
          url: postUrl
        }
      });
    } catch (e) {
      // Error
    }
    setIsLoading(false);
  }

  async function testPostUrl() {
    setIsLoading(true);
    try {
      await API.post("garage-api", "/settings", {
        body: {
          testUrl: postUrl
        }
      });
    } catch (e) {
      // Error
    }
    setIsLoading(false);
  }

  return (
    <>
       <div className="Settings">
        <h3>Settings</h3>

         <div className="p-3">
          <Card>
            <Card.Body>
              <Card.Title>Account Information</Card.Title>
              <div className="form-group">
                <label htmlFor="inputUserEmail">User email</label>
                <input type="text" className="form-control" id="inputUserEmail" disabled value={userDetails.email} />
              </div>
              <div className="form-group">
                <label htmlFor="inputDeviceSerial">Device Serial</label>
                <input type="text" className="form-control" id="inputDeviceSerial" disabled value={userDetails.serial} />
              </div>
              <div className="form-group">
                <label htmlFor="inputDeviceFirmware">Firmware Version</label>
                <input type="text" className="form-control" id="inputDeviceFirmware" disabled value={userDetails.firmwareVersion} />
              </div>
              <div className="form-group">
                <label htmlFor="inputLastContact">Last Contact Time</label>
                <input type="text" className="form-control" id="inputLastContact" disabled value={userDetails.lastTime} />
              </div>
              <LoaderButton
                block
                type="button"
                className="btn btn-primary"
                isLoading={isLoading}
                onClick={changePassword}
              >
                Change Password
              </LoaderButton>
              <LoaderButton
                block
                type="button"
                className="btn btn-primary"
                isLoading={isLoading}
                onClick={globalSignout}
              >
                Sign out of all devices
              </LoaderButton>
            </Card.Body>
          </Card>
        </div>

         <div className="p-3">
          <Card>
            <Card.Body>
              <Card.Title>Alert Settings</Card.Title>
              <form>
                <div className="form-group">
                  <label htmlFor="inputPostUrl">POST URL</label>
                  <input type="text" className="form-control" id="inputPostUrl" placeholder="Enter URL" value={postUrl} onChange={e => setPostUrl(e.target.value)} />
                  <small id="inputPostUrlHelp" className="form-text text-muted">Specify a URL that we'll update any time the status changes.</small>
                </div>
                <LoaderButton
                  block
                  type="button"
                  className="btn btn-primary"
                  isLoading={isLoading}
                  onClick={testPostUrl}
                >
                  Test URL
                </LoaderButton>
                <LoaderButton
                  block
                  type="button"
                  className="btn btn-primary"
                  isLoading={isLoading}
                  onClick={saveSettings}
                >
                  Save settings
                </LoaderButton>
              </form>
            </Card.Body>
          </Card>

        </div>
      </div>
    </>
  );
}