import React, { PureComponent } from 'react'
import { Line } from 'react-chartjs-2';


// Define some colours for us to use
const chartColors = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
};


// Define the empty graph data - we update this via websockets
const defaultData = {
  labels: [],
  datasets: [
    {
      label: 'Probe 1',
      backgroundColor: chartColors.red,
      borderColor: chartColors.red,
      data: [],
      fill: false,
      pointRadius: 0,
    }, {
      label: 'Probe 2',
      backgroundColor: chartColors.orange,
      borderColor: chartColors.orange,
      data: [],
      fill: false,
      pointRadius: 0,
    }, {
      label: 'Probe 3',
      backgroundColor: chartColors.green,
      borderColor: chartColors.green,
      data: [],
      fill: false,
      pointRadius: 0,
    }, {
      label: 'Probe 4',
      backgroundColor: chartColors.blue,
      borderColor: chartColors.blue,
      data: [],
      fill: false,
      pointRadius: 0,
    }, {
      label: 'Probe 5',
      backgroundColor: chartColors.purple,
      borderColor: chartColors.purple,
      data: [],
      fill: false,
      pointRadius: 0,
    }, {
      label: 'Probe 6',
      backgroundColor: chartColors.yellow,
      borderColor: chartColors.yellow,
      data: [],
      fill: false,
      pointRadius: 0,
    }
  ]
};


// Define the chart options
const chartOptions = {
  responsive: true,
  title: {
    display: false
  },
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  hover: {
    mode: 'nearest',
    intersect: true
  },
  scales: {
    xAxes: [{
      display: true,
      scaleLabel: {
        display: true,
        labelString: 'Time'
      }
    }],
    yAxes: [{
      display: true,
      scaleLabel: {
        display: true,
        labelString: 'Temperature'
      }
    }]
  }
};


export default class BbqLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {data: defaultData};
    props.onSocketConnection((s) => {
      s.addEventListener('message', this.onWsMessage);
      s.send(JSON.stringify({'action':'data'}));
    })
  }


  onWsMessage = (e) => {
    var msg = JSON.parse(e.data);
    var i;
    var j;
    var thisVal;
    var dataCopy = null;
    var datasetsCopy = this.state.data.datasets.slice(); // copy array
    var labelsCopy = this.state.data.labels.slice(); // copy array
    if (msg.type === 'data') {
      labelsCopy = [];
      datasetsCopy[0].data = [];
      datasetsCopy[1].data = [];
      datasetsCopy[2].data = [];
      datasetsCopy[3].data = [];
      datasetsCopy[4].data = [];
      datasetsCopy[5].data = [];
      for (i = 0; i < msg.data.length; i++) {
        // for each set of readings
        labelsCopy.push(msg.data[i].time);
        thisVal = msg.data[i].vals;
        for (j = 0; j < thisVal.length; j++) {
          // for each reading in a set (of 6)
          dataCopy = datasetsCopy[j].data.slice(0);
          dataCopy.push(thisVal[j]);
          datasetsCopy[j].data = dataCopy;
        }
      }
      this.setState({
        data: Object.assign({}, this.state.data, {
            datasets: datasetsCopy,
            labels: labelsCopy
        })
      });
    }
    if (msg.type === 'temperatures') {
      labelsCopy.push(msg.time);
      for (j = 0; j < msg.vals.length; j++) {
        // for each reading in a set (of 6)
        dataCopy = datasetsCopy[j].data.slice(0);
        dataCopy.push(msg.vals[j]);
        datasetsCopy[j].data = dataCopy;
      }
      this.setState({
        data: Object.assign({}, this.state.data, {
            datasets: datasetsCopy,
            labels: labelsCopy
        })
      });
    }
  }


  render() {
    return (
      <div>
        <Line data={this.state.data} options={chartOptions} />
      </div>
    );
  }
}