const dev = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://bbq-api.penguinseatcheese.co.uk"
  },
  wsApiGateway: {
    URL: "wss://bbq-ws.penguinseatcheese.co.uk/"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_Nz3Hukfv6",
    APP_CLIENT_ID: "23ccf59kh44fvi4iq66glgvuns",
    IDENTITY_POOL_ID: "eu-west-1:517709df-5d22-4366-b8c3-4c57b85f10ad"
  }
};

const prod = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://n3obxytmdg.execute-api.eu-west-1.amazonaws.com/prod"
  },
  wsGateway: {
    REGION: "eu-west-1",
    URL: "wss://0jc6gnfo1g.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "YOUR_PROD_COGNITO_USER_POOL_ID",
    APP_CLIENT_ID: "YOUR_PROD_COGNITO_APP_CLIENT_ID",
    IDENTITY_POOL_ID: "YOUR_PROD_IDENTITY_POOL_ID"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  ...config
};
