import React, { PureComponent } from 'react'
import { Navbar } from "react-bootstrap";


export default class BbqStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {bbqStatus: "...", style: {color: "white"}};
    this.timer = null;
    this.s = null;
    props.onSocketConnection((s) => {
      this.s = s;
      s.addEventListener('message', this.onWsMessage, s);
      s.send(JSON.stringify({'action':'status'}));
    })
  }

  onTimeout = (s) => {
    this.s.send(JSON.stringify({'action':'status'}));
  }

  onWsMessage = (e) => {
    var msg = JSON.parse(e.data);
    if (msg.type === 'status') {
      clearTimeout(this.timer);
      if (msg.online) {
        this.setState({bbqStatus: "Online", style: {color: "white"}});
        this.timer = setTimeout(this.onTimeout, 20000);
      } else {
        this.setState({bbqStatus: "Offline", style: {color: "red"}});
      }
    }
  };


  render() {
    return (
      <Navbar.Text id="statusIndicator" style={this.state.style}><i>{this.state.bbqStatus}</i></Navbar.Text>
    );
  }
}