import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import config from "./config";
import Routes from "./Routes";
import StatusIndicator from "./components/StatusIndicator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/free-solid-svg-icons'
import "./App.css";


class App extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {'isAuthenticating': false,
                  'isAuthenticated' : false}
    this.ws = null;
    this.wsOnOpen = []; // from child components
    this.timeout = 250; // Initial timeout duration as a class variable
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // STARTUP / USER LOGIN|OUT
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.setState({'isAuthenticating': false,
                     'isAuthenticated' : true})
      this.connectWebsocket();
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
      this.setState({'isAuthenticating': false,
                     'isAuthenticated' : false})
    }
  }

  async handleLogout() {
    await Auth.signOut();
    this.setState({'isAuthenticating': false,
                   'isAuthenticated' : false})
    this.props.history.push("/login");
  }

  userHasAuthenticated(haveThey) {
    console.log(haveThey);
    this.setState({'isAuthenticating': false,
                   'isAuthenticated' : haveThey})
    this.checkWebsocket();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // WEBSOCKET
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  onSocketConnection(e) {
    // Called by child components to add things to do on connection of websocket
    this.wsOnOpen.push(e);
  }

  async connectWebsocket() {
    var x = await Auth.currentSession();
    var wsUrl = config.wsApiGateway.URL + "?token=" + x.accessToken.jwtToken;
    var ws = new WebSocket(wsUrl);
    let that = this; // cache the this
    var connectInterval;

    ws.onopen = () => {
//      console.log("connected websocket main component");
      this.setState({ ws: ws });
      that.timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
      // Now run all the functions that child components added
      for (const f of this.wsOnOpen) {
        f(ws);
      }
    };

    ws.onclose = e => {
//      console.log(
//        `Socket is closed. Reconnect will be attempted in ${Math.min(
//          10000 / 1000,
//          (that.timeout + that.timeout) / 1000
//        )} second.`,
//        e.reason
//      );
      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.checkWebsocket.bind(this), Math.min(10000, that.timeout)); //call check function after timeout
    };

    // websocket onerror event listener
    ws.onerror = err => {
//      console.error(
//        "Socket encountered error: ",
//        err.message,
//        "Closing socket"
//      );
      ws.close();
    };
  };

  checkWebsocket() {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) {
      // check if websocket instance is closed, if so call `connectWebsocket` function.
      this.connectWebsocket();
    }
  };



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  render() {
    var isAuthenticated = this.state.isAuthenticated;
    var userHasAuthenticated = this.userHasAuthenticated.bind(this);
    var onSocketConnection = this.onSocketConnection.bind(this);
    return (
      !this.isAuthenticating &&
      <div className="App container">
        <Navbar className="fixed-top" expand="lg" variant="dark" bg="dark">
          <Navbar.Brand><Link to="/"><FontAwesomeIcon icon={faFire} className="fa-fw"/>  BBQ</Link></Navbar.Brand>
            {this.state.isAuthenticated ? ( <StatusIndicator onSocketConnection={onSocketConnection} /> ) : ( <></> )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {isAuthenticated ? (
                <>
                  <NavDropdown title="Settings" id="settings-dropdown">
                    <LinkContainer to="/probes">
                      <NavDropdown.Item>Connected Probes</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/probeTypes">
                      <NavDropdown.Item>Probe Types</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <Nav.Link onClick={this.handleLogout.bind(this)}>Logout</Nav.Link>
                </>
              ) : (
                <>
                  <LinkContainer to="/register">
                    <Nav.Link>Register</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated, onSocketConnection }} />
      </div>
    );
  }
}

export default withRouter(App);