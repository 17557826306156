import React from "react";
import "./Home.css";
import BbqLineChart from "../components/BbqLineChart";
import BbqTempReading from "../components/BbqTempReading";


export default function MainPage(props) {

  return (
    <div className="Main">
      <div className="container">
        <div className="row">
          <div className="col-md align-self-center text-center">
            <BbqLineChart onSocketConnection={props.onSocketConnection} />
          </div>
        </div>
        <div className="row">
          <div className="col-md align-self-center">
            <BbqTempReading probe={1} onSocketConnection={props.onSocketConnection} />
          </div>
          <div className="col-md align-self-center">
            <BbqTempReading probe={2} onSocketConnection={props.onSocketConnection} />
          </div>
          <div className="col-md align-self-center">
            <BbqTempReading probe={3} onSocketConnection={props.onSocketConnection} />
          </div>
        </div>
        <div className="row">
          <div className="col-md align-self-center">
            <BbqTempReading probe={4} onSocketConnection={props.onSocketConnection} />
          </div>
          <div className="col-md align-self-center">
            <BbqTempReading probe={5} onSocketConnection={props.onSocketConnection} />
          </div>
          <div className="col-md align-self-center">
            <BbqTempReading probe={6} onSocketConnection={props.onSocketConnection} />
          </div>
        </div>
      </div>
    </div>
  );
}



