import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Register from "./containers/Register";
import ChangePassword from "./containers/ChangePassword";
import ResetPassword from "./containers/ResetPassword";
import MainPage from "./containers/MainPage";
import Settings from "./containers/Settings";
import Probes from "./containers/Probes";
import ProbeTypes from "./containers/ProbeTypes";
import NotFound from "./containers/NotFound";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/register" exact component={Register} appProps={appProps} />
      <AuthenticatedRoute path="/main" exact component={MainPage} appProps={appProps} />
      <AuthenticatedRoute path="/probes" exact component={Probes} appProps={appProps} />
      <AuthenticatedRoute path="/probeTypes" exact component={ProbeTypes} appProps={appProps} />
      <AuthenticatedRoute path="/settings" exact component={Settings} appProps={appProps} />
      <AuthenticatedRoute exact path="/settings/password">
        <ChangePassword />
      </AuthenticatedRoute>
      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}
