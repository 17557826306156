import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";


export default function ProbeTypes(props) {
  // State settings for modal
  const [show, setShow] = useState(false);
  const [probeName, setProbeName] = useState("");
  const [temp1, setTemp1] = useState("");
  const [temp2, setTemp2] = useState("");
  const [temp3, setTemp3] = useState("");
  const [calib1, setCalib1] = useState(0);
  const [isCalib1, setIsCalib1] = useState(false);
  const [calib2, setCalib2] = useState(0);
  const [isCalib2, setIsCalib2] = useState(false);
  const [calib3, setCalib3] = useState(0);
  const [isCalib3, setIsCalib3] = useState(false);

  const [probesList, setProbesList] = useState([]);

  const handleClose = () => setShow(false);

  function handleShow() {
    setProbeName('');
    setTemp1('');
    setTemp2('');
    setTemp3('');
    setCalib1('');
    setCalib2('');
    setCalib3('');
    setIsCalib1(false);
    setIsCalib2(false);
    setIsCalib3(false);
    setShow(true);
  }

  useEffect(() => {
    updateTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  async function getCalibrationReading(setCalibFunc, setIsCalibFunc) {
    setIsCalibFunc(true);
    try {
      var retValue = await API.get("bbq-api", "/getCalibrationReading");
      setCalibFunc(retValue.reading);
    } catch (e) {
    }
    setIsCalibFunc(false);
  }

  async function updateTable() {
    var retValue = await API.get("bbq-api", "/probeType");
    console.log(retValue);
    retValue.forEach(function(part, index, theArray) {
      theArray[index]['deleteButton'] = <Button className="btn-danger" onClick={() => deleteProbe(theArray[index]['id'])}>Delete</Button>;
    });
    setProbesList(retValue);
  }

  function validateForm() {
    return temp1 !== '' && temp2 !== '' && temp3 !== '' && probeName !== '' && calib1 !== '' && calib2 !== '' && calib3 !== '';
  }

  async function deleteProbe(probeId) {
    // TODO: Make this a spinny loader button
    await API.del("bbq-api", `/probeType/${probeId}`);
    updateTable();
  }

  async function saveNewProbe() {
    try {
      await API.post("bbq-api", "/probeType", {
        body: {
          name: probeName,
          t1: temp1,
          r1: calib1,
          t2: temp2,
          r2: calib2,
          t3: temp3,
          r3: calib3
        }
      });
    } catch (e) {
      console.log(e);
    }
    setShow(false);
    updateTable();
  }


  const tableColumns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "name",
      text: "Name"
    },
    {
      dataField: "date",
      text: "Added"
    },
    {
      dataField: "deleteButton",
      text: ""
    }
  ];

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new probe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group form-row">
              <label htmlFor="newProbeName" className="col-sm-3 col-form-label">Name</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="newProbeName" value={probeName} onChange={e => setProbeName(e.target.value)} />
              </div>
            </div>
            <div className="form-group form-row">
              <label htmlFor="newProbeReading1" className="col-sm-3 col-form-label">Reading 1</label>
              <div className="col-sm-3">
                <input type="text" className="form-control" id="newProbeReading1" placeholder="degF" value={temp1} onChange={e => setTemp1(e.target.value)} />
              </div>
              <div className="col-sm-3">
                <input type="text" className="form-control" id="newProbeValue1" value={calib1} onChange={e => setCalib1(e.target.value)} />
              </div>
              <div className="col-sm-3">
                <LoaderButton
                  block
                  type="button"
                  className="btn btn-primary"
                  isLoading={isCalib1}
                  onClick={() => getCalibrationReading(setCalib1, setIsCalib1)}
                >
                  Read
                </LoaderButton>
              </div>
            </div>
            <div className="form-group form-row">
              <label htmlFor="newProbeReading2" className="col-sm-3 col-form-label">Reading 2</label>
              <div className="col-sm-3">
                <input type="text" className="form-control" id="newProbeReading2" placeholder="degF" value={temp2} onChange={e => setTemp2(e.target.value)} />
              </div>
              <div className="col-sm-3">
                <input type="text" className="form-control" id="newProbeValue2" value={calib2} onChange={e => setCalib2(e.target.value)} />
              </div>
              <div className="col-sm-3">
                <LoaderButton
                  block
                  type="button"
                  className="btn btn-primary"
                  isLoading={isCalib2}
                  onClick={() => getCalibrationReading(setCalib2, setIsCalib2)}
                >
                  Read
                </LoaderButton>
              </div>
            </div>
            <div className="form-group form-row">
              <label htmlFor="newProbeReading3" className="col-sm-3 col-form-label">Reading 3</label>
              <div className="col-sm-3">
                <input type="text" className="form-control" id="newProbeReading3" placeholder="degF" value={temp3} onChange={e => setTemp3(e.target.value)} />
              </div>
              <div className="col-sm-3">
                <input type="text" className="form-control" id="newProbeValue3" value={calib3} onChange={e => setCalib3(e.target.value)} />
              </div>
              <div className="col-sm-3">
                <LoaderButton
                  block
                  type="button"
                  className="btn btn-primary"
                  isLoading={isCalib3}
                  onClick={() => getCalibrationReading(setCalib3, setIsCalib3)}
                >
                  Read
                </LoaderButton>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveNewProbe} disabled={!validateForm()}>
            Add probe
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="ProbeTypes">
        <h3>Probe Types</h3>
        <BootstrapTable striped keyField="id" data={probesList} columns={tableColumns} />
        <button type="button" className="btn btn-primary" onClick={handleShow}>
          Add new probe type
        </button>
      </div>
    </>
  );
}